<template>
    <div>
        <img :src="require('../assets/images/IMG-RecursoNoEncontrado.png')" />
    </div>
</template>

<script>

    export default {
        name: "resource-not-found",
        data() {
            return {
            };
        },
        mounted() {
        }
    };
</script>

<style scoped>

</style>